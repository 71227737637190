/*Die Überschriftszeile*/
.dayNumericContent {
    padding: 0.25rem;
    margin: 0.1rem 0 0.24rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
}

/*Überschriftszeile*/
.dayTextContent {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 0.5rem;
    font-weight: bold;
}

.calendarCellMonth {
    border-style: solid;
    border-width: 1px;
    border-color: var(--light-contrast);
    display: flex;
    flex-direction: column;
    height: 100%;
}

/*Der ganze Kalender (außen)*/
.calendarCell {
    position: relative;
    border: 1px solid var(--light-contrast);
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

/*Über dem Calendar das Weiterschalten, heute usw..*/
.calendarHeader {
    display: flex;
    align-items: center;
    top: 0;
    margin-bottom: 10px;
}

/*Heute-Button*/
.button {
    padding: 0.5rem 1rem;
    margin-right: 1.25rem;
    border-radius: var(--borderRadius) !important;
    /*border: 1px solid;*/
    width: 5%;
    background-color: white;
    color: black;
    text-transform: none !important;
}

.button:hover {
    filter: brightness(97%);
}

/*Überschrift über dem Wochen-/MonatsGrid*/
.monthHeader {

    margin-left: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    margin: 0;
    color: var(--secondary);
}

/*Kleiner Kalender links oben*/
.calendarSidebar {
    border: 1px solid var(--light-contrast);
    padding: 0.75rem;
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
    width: 16rem;
    height: 100%;
}

@media (max-width: 768px) {
    .calendarSidebar {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--light-contrast);
        padding: 0.75rem;
        border-top-left-radius: var(--borderRadius);
        border-bottom-left-radius: var(--borderRadius);
        width: 90%;
        height: 100%;
        overflow: auto;
    }
}

/*u.a.d. Button links "+Termin mit dem Pfeil nach unten"*/
.createAppointment,
.groupOptionsButton,
.groupToggleButton {
    color: black !important;
    background-color: white !important;
    display: flex !important;
    padding: 0.5rem !important;
    align-items: center !important;
    flex-direction: row !important;
    border: 1px solid var(--brand-light) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    width: auto !important;
    text-transform: none !important;
}

.createAppointment:hover,
.groupOptionsButton:hover,
.groupToggleButton:hover {
    box-shadow: 0 10px 15px -3px rgba(224, 9, 9, 0.1), 0 4px 6px -2px rgba(226, 26, 26, 0.05) !important;
    background-color: var(--brand-light-light) !important;
}

.groupOptionsButton {
    border-top-left-radius: var(--borderRadius) !important;
    border-bottom-left-radius: var(--borderRadius) !important;
}

.groupToggleButton {
    border-top-right-radius: var(--borderRadius) !important;
    border-bottom-right-radius: var(--borderRadius) !important;
    border-width: 0 !important;
}

.buttonGroupRadius {
    border-radius: var(--borderRadius) !important;
}

/*Trennlinie nach dem kleinen Kalendar*/
.calendarCategoryContainer {
    border-top: 1px solid var(--light-contrast);
    padding-top: 1rem;
}

/*Kategorieliste links*/
.listItemText,
.categoryTextElement {
    margin: 0;
    display: flex;
    align-items: center;
    /*overflow: hidden;*/
    white-space: nowrap;
    text-overflow: ellipsis;
}

.categoryTextElement {
    max-width: 80%;
}

.colorPicker {
    height: 40px;
    border: 1px solid lightgray;
    background-color: #f5f5f5;
}

.categoriesParentElement {
    overflow: hidden;
}

@media (min-width: 577px) {
    .categoryButtonContainer {
        margin-right: 0;
        margin-left: auto;
    }

    .categoryButtons {
        display: none !important;
    }

    .categoriesParentElement:hover .categoryButtons {
        display: block !important;
    }

    .categoryTextElement {
        transition: flex 0.2s ease-in-out !important;
    }
}

.smallCalendar {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));
    margin-bottom: 1rem;
}

.weekHour,
.hourContent,
.appointment,
.task {
    /*Termine, z.b. Schriftgröße*/
    position: relative;
    padding: 5px;
    margin: 0px 0;
    border-radius: 3px;
    font-size: 0.9rem;
    /*overflow: hidden;/*Damit werden die Zellen viel höher!*/
    box-sizing: border-box;
}

.appointmentMonth {
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: darkslategray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--brand-light);
    transition: all 0.3s ease-in-out;
}

.hourLabel {
    font-weight: bold;
    font-size: 0.9rem;
}

/*Hier gestalte ich die Rahmenbedingungen einer Zelle, eindeutig:*/
.weekHour {
    min-height: 60px;
    /* Minimum height for each hour block */
    border-bottom: 0.1px solid #776f6f;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    overflow-y: auto;
    /* Ensure the content does not overflow */
    /*overflow-x: auto;*/
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

/*Inhalt einer Zelle*/
.hourContent {
    display: flex;
    flex-direction: column;
    /* Align items in a row */
    /*flex-wrap: wrap; /* Ensure appointments are wrapped to the next line if necessary */
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    /* Allow the hour content to grow with appointments */
}

.appointment {
    background-color: var(--brand-light);
    border:  1px solid #776f6f;
    color: darkslategray;
    transition: all 0.3s ease-in-out;
    text-overflow: ellipsis;
    min-height: 30px;
    overflow: hidden;
    padding: 5px;
    border-radius: 5px;
    display: block;
    align-items: center;
    justify-content: left;
    word-wrap: break-word;
    /* Allow text to wrap within the box */
    position: absolute;
    /* Allow the appointment to span multiple rows */
    /* width: calc(100% / var(--appointment-count)); /* Adjust width based on the number of overlapping appointments */
    box-sizing: border-box;
}

/* Dynamic widths based on the number of overlapping appointments */
.appointment.count-1 {
    width: 100%;
}

.appointment.count-2 {
    width: 50%;
}

.appointment.count-3 {
    width: 33.33%;
}

.appointment.count-4 {
    width: 25%;
}

/* Add more as needed */

.appointment span {
    white-space: normal;
    /* Allow line breaks in the span */
}

.appointment:hover {
    filter: brightness(95%);
}

.holiday {
    background: #32b13d;
    color: white;
    position: relative;
    padding: 5px;
    border-radius: 3px;
    margin: 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.weekGrid {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    /* Maximum height for the grid */
    overflow-y: scroll;
    /* Allow scrolling within the grid */
    position: relative;
}

.hourSeparator {
    border: none;
    /*border-top: 1px solid #ccc;*/
    margin: 0;
}

.task {
    background-color: #000000;
    color: white;
    transition: all 0.3s ease-in-out;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    /* Allow text to wrap within the box */
    width: 100%;
    /* Ensuring each task takes the full width */
    box-sizing: border-box;
}

.task span {
    white-space: normal;
    /* Allow line breaks in the span */
}

.task:hover {
    filter: brightness(95%);
}

.monthGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(100px, auto);
    /* Ensure rows can expand to fit content */
    height: 100%;
    width: 100%;
}