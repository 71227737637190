.container {
    padding: 20px;
    border-radius: var(--borderRadius);
    background-color: var(--light);
}

.dropdown {
    position: absolute;
    margin-top: 30px;
    padding: 10px 20px;
    background-color: var(--light);
    margin-left: -20px;
    border-radius: var(--borderRadius);
}