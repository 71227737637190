.row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px;
}

.row:hover {
    background-color: var(--light) !important;
    transition: background-color 0.3s ease !important;
}

.titleContainer {
    width: 47%;
    margin-right: 3%;
    word-wrap: break-word;
}

.countContainer {
    width: 15%;
    margin-right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: black solid 1px;
    border-radius: 8px;
    gap: 4px;
    background-color: var(--brand-light);
}

.checkbox {
    height: 16px;
    width: 16px;
    margin: 0px 4px 0px 4px;
    accent-color: var(--brand);
}

.label {
    margin: 8px;
}