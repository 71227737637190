.newsline-container {
    position: absolute;
    width: 80%;
    bottom: 0;
    right: 3%;
    left: 18.5%;
    height: 31px;
    display: flex;
    overflow: hidden;
    --ticker-items: 8; /* Anzahl der Ticker-Elemente */
    background-color: #F2EBFF;
    color: #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
  }
  

  
  .news-item {
    display: inline-block;
    white-space: nowrap;
    margin-right: 0%;
    margin-left: 0%;
    margin-top:3px;
    animation: news-item linear infinite;
    animation-duration: calc(10s * var(--ticker-items) / 8);
    animation-iteration-count: infinite;
  }

  /*
  @keyframes news-item {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
 /* Dies führt dazu, dass die Ticker-Elemente von rechts hereinkommen und dann durchlaufen, bis sie am linken Rand verschwinden.*/
  
  
  
  
  
  
  