.kachel-container {
  display: flex;
  flex-wrap: wrap;
}

.kachel-row {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.kachel {
  flex: 1 0 auto;
  margin: 10px;
  border-radius: 10px;
  background-color: #F2EBFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kachel-content {
  text-align: center;
}

.tile-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%; /* Set the maximum width for your container */
  justify-content: center;
}

.tile {
  background-color: #F2EBFF;
  border-radius: 10px;
  padding: 1%;
  margin: 2px;
}