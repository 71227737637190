.container {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.container:hover {
    background-color: var(--light-contrast);
}

.containerArrowHover {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--light-contrast);
}