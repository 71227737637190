.picture-upload-container {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
    border-bottom: 1px solid rgb(94, 87, 87);
    border-radius: 1px;
}

.crop-container {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vh;
    margin-right: 2vh;
    margin-bottom: 2vh;
}

.completed-crop-container {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vh;
    margin-right: 2vh;
}

.text {
    display: block;
    text-align: center;
    margin-bottom: .5vh;
}