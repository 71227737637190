.container {
    padding: 0px 0;
    padding-left: 2px;
    margin-inline: 0px;
}

.accent {
    height: 14px;
    width: 14px;
    border-radius: 100px;
    background-color: green;
}