@media print {

	.noprint,
	.noprint * {
		display: none;
	}

	.invoicepanel {
		border: 0 solid #fff;
		box-sizing: border-box;
		font-size: 3.1mm;
		width: 70mm;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		box-sizing: border-box;
		background-color: #fff;
	}
}

@media screen {
	.invoicepanel {
		border: 1px solid #aaa;
		font-size: 3.1mm;
	}
}

.container {
	margin: 0;
	padding: 0;
	border: solid 0 #fff;
}

.containerInside {
	font-family: sans-serif;
	font-size: 3.1mm;
}

.invoicepanel {
	width: 70mm;
	padding: 15px;
	margin: 0 auto;
	text-align: center;
	box-sizing: border-box;
	background-color: #fff;
}

.invoicepanel h1 {
	font-size: 8mm;
	margin: 1mm 0 2.5mm 0;
	padding: 0 0 2.5mm 0;
	text-align: center;
	width: 100%;
	border-bottom: dashed 0.4mm #000;
}

.cell0,
.cell1,
.cell2,
.cell3,
.cell4 {
	font-size: 2.8mm;
}

.cell0,
.cell4 {
	text-align: center;
}

.cell1 {
	text-align: left;
}

.cell2,
.cell3 {
	text-align: right;
}

.cell1 {
	line-height: 3.1mm;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.dottedline {
	margin-bottom: 2.5mm;
	padding-bottom: 2.5mm;
	border-bottom: dashed 0.4mm #000;
}

.bold {
	font-weight: bold;
}

.testMode {
	font-size: 5mm;
	border-top: 0.4mm solid #000;
	border-bottom: 0.4mm solid #000;
	margin: 1.3mm;
	padding: 1.3mm;
}

.logo {
	margin: 0 auto;
	padding-bottom: 5mm;
	padding-top: 5mm;
	filter: gray;
	filter: grayscale(100%);
}

.table {
	display: table;
	border-collapse: separate;
}

.tabletd {
	display: table-cell;
}

.break,
table td {
	word-wrap: break-word;
}

.tablefixed {
	width: 100%;
	table-layout: fixed;
}

.imgbon {
	-webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: gray;
	filter: grayscale(100%);
}

.spacebottom {
	margin-bottom: 2.5mm;
}

.spacetop {
	margin-top: 2.5mm;
}