.accent {
    height: 12px;
    width: 4px;
    border-radius: 0 10px 10px 0;
    background-color: var(--brand);
}

.container {
    margin: 0 -10px;
    padding: 10px 10px 10px 0px;
}