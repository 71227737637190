.container {
    padding: 10px;
    background-color: var(--light) !important;
    box-sizing: border-box;
    border-radius: var(--borderRadius);
    width: 100%;
    border: 1.5px solid var(--light) !important;
}

.containerError {
    padding: 10px;
    background-color: var(--light) !important;
    box-sizing: border-box;
    border-radius: var(--borderRadius);
    width: 100%;
    border: 1.5px solid red !important;
}

.datePickerContainer {
    display: flex;
    align-items: center;
    border-radius: var(--borderRadius);
    background-color: #EBEBEB;
}

.container:focus-within {
    border: 1.5px solid var(--brand);
}

.containerError:focus-within {
    border: 1.5px solid var(--brand);
}

.container:focus {
    border: 1.5px solid var(--brand);
}

.containerError:focus {
    border: 1.5px solid var(--brand);
}

.wrapper {
    gap: 5px;
}

.container > input, textarea {
    outline: none !important;
    background-color: transparent !important;
    border: none;
}

.containerError > input, textarea {
    outline: none !important;
    background-color: transparent !important;
    border: none;
}

.container > div > fieldset {
    border: none !important;
}

.containerError > div > fieldset {
    border: none !important;
}

.container > div > input {
    padding-inline: 10px;
    font-size: 14px;
}

.containerError > div > input {
    padding-inline: 10px;
    font-size: 14px;
}

.button {
    outline: none;
    border: none;
    background-color: transparent;
}

.dateInput > div > div > fieldset {
    border: none !important;
}

.input {
    color: "#FF9494"
}

.error{
    font-size: 12px;
    padding: 3px;
    color: red
}

.errorLogin{
    font-size: 15px;
    padding: 3px;
    padding-top: 18px;
    text-align: center;
    color: red;
    
}