.container {
    padding: 40px;
    border-radius: var(--borderRadiusOuter);
    background-color: var(--background);
    width: 50%;
    gap: 10px;
}

@media all and (max-width: 768px) {
    .container {
        gap: 6vh;
        width: 100%;
    }
}