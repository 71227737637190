.hamburgerButton {
    background-color: transparent;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
}

.hamburgerInner:nth-of-type(1) {
    transform: translateY(-3px) rotate(0deg);
}
.hamburgerInner:nth-of-type(2) {
    transform: translateY(3px) rotate(0deg);
}

.isOpen {
    transition: all 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1s;
}

.isOpen:nth-of-type(1) {
    transform:  translateY(1px) rotate(45deg) ;
}
.isOpen:nth-of-type(2) {
    transform:  translateY(-1px) rotate(-45deg) ;
}
.hamburgerInner {
    background-color: var(--text);
    width: 20px;
    height: 2px;
    transition: all 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1s;
}