.active {
    color: white !important;
}

.activeContainer {
    background-color: var(--brand);
    border-radius: var(--borderRadius);
}

.activeContainer:hover {
    background-color: var(--brand) !important;

}

.subActiveContainer {
    background-color: var(--brand-light);
    border-radius: var(--borderRadius);
}

.subActiveContainer > a {
    color: var(--text) !important;
}

.subActiveContainer:hover {
    background-color: var(--brand-light) !important;
    border-radius: var(--borderRadius);
}


.linkContainer:hover {
    background-color: var(--light);
}

.linkContainer {
    transition: background-color 0.2s;
    border-radius: var(--borderRadius);

}

.navbar {
    height: var(--navbar-height);
    background-color: var(--background);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    padding: 10px;
}

.item {
    padding: 0.33em 0.66em;
    font-size: 1em;
    color: var(--text-contrast);
    fill: var(--text-contrast);
    font-weight: var(--regular);
    text-decoration: none;
    gap: 0.66em;
    border-radius: var(--borderRadius);

}

.subItem {
    text-decoration: none;
    padding: 8px 10px;
    margin-left: 10px;
    border-radius: var(--borderRadius);
    color: var(--text-contrast);
    fill: var(--text-contrast);
    font-weight: var(--regular);
}

.subItem:hover {
    background-color: var(--light);
}

.subItemActive {
    background-color: var(--brand) !important;
    color: white;
}

.subItemActive:hover {
    background-color: var(--brand) !important;
}


.navigation {
    padding: 20px;
    width: 100%;
    background-color: var(--background);
    height: 100vh;
}

/* Mobile-Nav */
.mobileContainer {
    background-color: var(--background);
    position: fixed;
    z-index: 98;
    inset: 50px 0 0;
    padding: 10px;
    overflow: scroll
}