.header {
    padding: 5px 10px;
    background-color: var(--ultra-light);
    /* box-shadow: 0 0 0 100vmax var(--ultra-light);
    clip-path: inset(0 -100vmax); */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container {
    padding: 0px 10px;
}

.wrapper {
    background-color: var(--ultra-light);
    margin: 0 -10px;
}