.row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px;
}

.row:hover {
    background-color: var(--light) !important;
    transition: background-color 0.3s ease !important;
}

.checkbox {
    height: 16px;
    width: 16px;
    margin: 0px 8px 0px 4px;
    accent-color: var(--brand);
}

.nameContainer {
    width: calc(100% - 24px);
}