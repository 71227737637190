.container {
    background-color: var(--elevated-background);
    border-radius: var(--borderRadius);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.unscrollableContainer {
    background-color: var(--elevated-background);
    border-radius: var(--borderRadius);
    overflow: hidden;
    position: relative;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
    display: none;
}


@media (max-width: 768px) {
    .container {
        border-radius: 0;
        min-height: calc(100vh - var(--navbar-height) - 50px);
        max-height: calc(100vh - var(--navbar-height) - 50px);;
        border-radius: var(--borderRadius);
    }
}

.ctaButton {
    width: 100%;
    padding: 10px;
    position: sticky;
    bottom: 0;
    background-color: var(--brand-light);
    color: var(--brand);
    outline: none;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .ctaButton {
        top: calc(100vh - var(--navbar-height) - 50px);
    }
}


.header {
    width: 100%;
    padding: 5px 10px;

    /* Icon-Button = 40px + 2*5px padding */
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerContainer {
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--light-contrast);
    background-color: var(--elevated-background);
}

.title {
    margin: 0;
    font-size: var(--t-regular);
    color: var(--text);
    font-weight: bold;
}



.ctaButton:active {
    background-color: red;
}

.body {
    padding: 0px;
    overflow: scroll;

}