@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --chart-green: 138 58% 39%;
    --chart-red: 5 76% 61%;
    --chart-1: 5 76% 61%;
    --chart-2: 138 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    
/*     --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem; */
  }

  /*   .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  box-sizing: border-box !important;
}

VariableSizeList {
  scrollbar-width: "none";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  max-height: 100%;
  background-color: var(--light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navHeader {
  position: fixed;
  /* Make the header stick to the top of the page */
  top: 0;
  left: 0;
  width: 100%;
  /* Use 100% of the page width */
  z-index: -1;
  /* move header behind side navbar*/
  max-height: var(--wrapper-height);
}

:root {
  --borderRadiusOuter: 20px;
  --borderRadius: 10px;
  --borderRadiusInner: 5px;
  --border: #f4f6f7,

  --p: 400;
  --regular: 500;
  --medium: 600;
  --bold: 800;

  --t-small: 12px;
  --t-regular: 16px;
  --t-medium: 18px;
  --t-headline: 20px;

  --back: #f5f5f5;
  --elevated-background: #fff;

  --background: #fff;
  --text: #000;

  --brand: #390099;
  --brand-clickable: #751aff;
  --brand-selected: #e8dcff;
  --brand-light: #F2EBFF;
  --brand-light-light: #F2EBFF80;
  --brand-contrast: #fff;
  --brand-attention: #EBA049;
  --brand-attention-light: #FFE9DB;

  --secondary: #707070;
  --secondary-light: #7070701d;

  --outgoing: #f00;
  --outgoing-ligh: #ff0000c0;


  --light: #f5f5f5;
  --ultra-light: #fafafa;
  --light-contrast: #e4e4e4;
  --text-contrast: #707070;

  --error: #F06543;
  --error-light: #FBE2DA;

  --blue: #00A8E8;
  --blue-light: #E5F6FD;
  --red-light: #ff0000a1;
  --green: #009e0da1;

  --page-height: 100vh;
  --page-margin: 20px;

  --navbar-height: 50px;
  --wrapper-height: 75px;

  --muted-foreground: #7f8ea3;
  --accent: #f1f5f9;
  --accent-foreground: #0f172a;

  --primary: #0f1729;
  --primary-foreground:#f8fafc;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

/*@media (prefers-color-scheme: dark) {
  :root {
    --brand: red;
    --background: #1F1B24;
    --text: white;
    --back: #1F1B24;
    --elevated-background: #3C3546;

    --light: #6E6080;
    --ultra-light: #28242E;
    --light-contrast: #645874;
    --text-contrast: #C1B9CA;

    --brand-light: #1B2F37;
    --brand: #345F6F;
  }
}*/

.navbar {
  padding: 10px;
  background-color: var(--background);
}

/* empty Container for triggering hover effect*/
.itemContainer {}

/*hover effect*/
.itemContainer:hover {
  background-color: var(--light) !important;
  transition: background-color 0.3s ease !important;
}