.dynamicFormContainer{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 1vh;
}

.dynamicFormButton {
    margin-top: 1.5vh;
    margin-right: 0.5vh;
    margin-left: 0.5vh;
}