.container {
    border-radius: var(--borderRadius) !important;
    min-width: 35%;
    overflow-y: visible !important;
}

.header {
    padding: 10px;
    border-bottom: 1px solid var(--light-contrast);
    background-color: var(--elevated-background);
    color: var(--text);
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
}

.back {
    background-color: var(--elevated-background);
}

.body {
    padding: 10px;
    background-color: var(--elevated-background);
    height: max-content;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
}

.body > * {
    margin-bottom: 24px;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
