.container {
    padding: 10px;
    border-radius: var(--borderRadius);
    background-color: var(--light);
    gap: 10px;
}
.container > input {
    outline: none;
    border: none;
    width: 100%;
    background-color: transparent;
}