.input {
    padding: 10px;
    background-color: var(--light);
    outline: none;
    border-radius: 10px;
    transition: border-radius 0.1s;
    width: 100%;
    border: 1.5px solid var(--light) !important;
}

.input > input {
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
}

.inputError {
    padding: 10px;
    background-color: var(--light);
    outline: none;
    border-radius: 10px;
    transition: border-radius 0.1s;
    width: 100%;
    border: 1.5px solid var(--error) !important;
}

.inputError > input {
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
}

.dropdown {
    border-radius: 0 0 10px 10px;
    background-color: var(--light);
    z-index: 99999 !important;
}

.deleteButton {
    padding: 0;
    margin: 0;
    display: flex;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}