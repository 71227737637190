.wrapper > button:first-child {
    border-radius: 10px 0 0 10px;
}
.wrapper > button:last-child {
    border-radius: 0 10px 10px 0;
}

.wrapper {
    gap: 2px;
}

.button {
    background-color: transparent;
    border: none;
    padding: 5px 9px;
    transition: background-color .2s;
}

.button:hover {
    background-color: var(--light);
}

.active {
    background-color: var(--brand-light);
    color: var(--brand);
}

.active:hover {
    background-color: var(--brand-light);
}