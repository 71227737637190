.itemEnter {
  opacity: 0;
}

.itemEnterActive {
  /*opacity: 1;
    transition: opacity 500ms ease-in-out;*/
  -webkit-animation: itemEnterActive 0.5s ease-in-out both;
  animation: itemEnterActive 0.5s ease-in-out both;
}

.itemEnterActiveRight {
  -webkit-animation: itemEnterActiveRight 0.5s ease-in-out both;
  animation: itemEnterActiveRight 0.5s ease-in-out both;
}

.itemEnterDone {
  opacity: 1;
}

.itemExit {
  opacity: 1;
}

.itemExitActive {
  opacity: 0;
  /*transition: opacity 100ms ease-in-out; issue: card appears at the bottom of the screen when this is active -> translateX for exit?*/
}

.itemExitDone {
  opacity: 0;
}

@-webkit-keyframes itemEnterActive {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes itemEnterActive {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes itemEnterActiveRight {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes itemEnterActiveRight {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}