.modalContainer {
    display: flex;
    position: fixed;
    top: 49vh;
    left: 19vw;
    justify-content: center;
    align-items: center;
    width: 30%;
    max-height: 48vh;
    min-height: 48vh;
    height: 48vh;
    box-sizing: border-box !important;
    z-index: 9999;
    box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.13);
}

.modalForm {
    border-radius: var(--borderRadius);
    width: 100%;
    background-color: #ffffff;
}

.modalHeader {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5vh;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--brand-light);
    position: relative;
    top: 0;
    border-top-right-radius: var(--borderRadius);
    border-top-left-radius: var(--borderRadius);
}

.content {
    padding: 1rem;
    overflow-y: visible;
    overflow-x: hidden;
    min-height: 40vh;
    height: 40vh;
    max-height: 40vh;
    box-shadow: 0 10px 15px 3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.13);
}

.modalFooter {
    display: flex;
    padding: 0.3rem;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.13);
    bottom: 0;
    background-color: whitesmoke;
}

@media (max-width: 768px) {
    .modalContainer {
        display: flex;
        position: fixed;
        top: 49vh;
        left: 12vw;
        justify-content: center;
        align-items: center;
        width: 79%;
        max-height: 48vh;
        height: 48vh;
        box-sizing: border-box !important;
    }
}