/* Flex-Hilfsklassen */
.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.centered-aligned {
    align-items: center;
}

.centered-justified {
    justify-content: center;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stretched-justify {
    justify-content: space-between;
}

.stretched-align {
    align-content: space-between;
}

.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.form-row-label {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.card-row-33 {
    height: 33%;
    display: flex;
    gap: 20px;
}

.card-row-66 {
    display: flex;
    height: 66%;
    gap: 20px;
}

.gap5 {
    gap: 5px;
}


/* Element-Klassen */
.page {
    background-color: var(--back);
    height: 100vh;
    width: 100%;
    box-sizing: border-box !important;
    gap: 20px;
    scroll-behavior: unset;
}


.page-base {
    padding: 30px
}

.nested-page {
    width: 100%;
    background-color: var(--back);

    padding: 30px;
    box-sizing: border-box !important;
    height: var(--page-height);
    gap: 20px;
}

@media (max-width: 768px) {
    .page {
        background-color: var(--back);
        height: 95vh;
        width: 100%;
        box-sizing: border-box !important;
        gap: 20px;
        scroll-behavior: unset;
    }

    .page-base {
        padding: 0;
    }

    .nested-page {
        overflow: scroll;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 0;
        background: transparent
    }
}

/* Text-Hilfsklassen */
.heading {
    font-size: var(--t-headline);
    font-weight: var(--medium);
    color: var(--text);
}

.subheading {
    font-size: var(--t-medium);
    font-weight: var(--medium);
    color: var(--text);
}

.bold-big {
    font-size: 14px;
    font-weight: var(--medium);
    color: var(--text);
}

.heading-small {
    font-size: var(--t-small);
    color: var(--text);
}

.contrast-text {
    font-weight: var(--regular);
}

.button-text {
    font-size: var(--regular);
    font-weight: var(--medium);
    color: var(--text);
}

.label {
    font-size: var(--t-small);
    color: var(--text);
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.scroll {
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: 0 !important;
    /* Firefox */
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
}

.scroll::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

.no-scrollbar {
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;

}

/* Hilfsklassen für Buttons */
.cta-button {
    background-color: var(--brand);
    border-radius: var(--borderRadius);
    padding: 10px 20px;
    font-weight: var(--medium);
    outline: none;
    border: none;
    color: var(--brand-light);
}

.cta-button-disabled {
    background-color: #cccccc;
    border-radius: var(--borderRadius);
    padding: 10px 20px;
    font-weight: var(--medium);
    outline: none;
    border: none;
    color: #666666;
}



.secondary-button {
    background-color: transparent;
    padding: 10px 20px;
    font-weight: var(--medium);
    color: var(--brand);
    outline: none;
    border: none;
}

.base-button {
    outline: none;
    border: none;
    background-color: transparent;
}

/* Hilfsklassen für Mauspointer */
.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

/* Hilfsklassen für Formulare*/

/* Hilfsklassen für Shadow */
.shadow {
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}

/* Hilfsklassen für Animation */
.transition {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

/* fertige Elemente */
.bubble {
    padding: 4px 10px;
    border-radius: 100px;
    background-color: var(--brand);
    color: var(--brand-contrast);
    font-weight: var(--regular);
    font-size: var(--t-small);
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.warning-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--borderRadius);
    background-color: var(--error-light);
    color: var(--error);
    font-size: 24px;
    padding: 10px;
}

@-moz-document url-prefix() {

    /* Hide scrollbar track */
    body {
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;
    }

    /* Hide scrollbar thumb */
    body::-webkit-scrollbar {
        display: none;
    }
}