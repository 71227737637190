:root {
    --longestItemLength: 0;
  }

.ticker {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: #f8f8f8;
    border-top: 1px solid #ddd;
    padding: 10px;
    font-size: 16px;
    height: auto;
  }
  
  

  .ticker-item {
   
    white-space: nowrap;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    left: 0;
    animation: ticker 20s linear infinite;
  }
  
  
  @keyframes ticker {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  